<template>
  <div>
    <b-sidebar
      id="sidebar-create"
      shadow
      right
      bg-variant="white"
      backdrop
      ref="sidebar-create"
      :title="dataProp.id == null ? 'Cadastrar Categoria' : 'Editar Categoria'"
    >
      <div>
        <validation-observer ref="simpleRules">
        <hr>
          <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
         <b-form enctype="multipart/form-data" method="POST">
          <b-form-group
            label="Nome:"
            label-for="i-nome"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="nome"
              rules="required"
            >
              <b-form-input
                id="i-nome"
                v-model="dataSegments.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Logo:"
            label-for="i-Icon"
            class="pl-1 pr-1"
          >
            <b-form-file
              placeholder="Logo"
              v-model="icon"
              type="file"
              accept="image/*"
              name="icon"
            />
          </b-form-group>
        </b-form>
        <b-row style="margin-right: 0rem !important;">
          <b-col lg="5" sm="6" class="ml-1 mr-1">
            <b-button
              variant="relief-primary"
              @click="storeSegments()"
              :disabled="dataSegments.name == null"
              block
            >
              {{this.dataSegments.id == null ? 'Salvar' : 'Editar'}}
            </b-button>
          </b-col>
          <b-col>
            <b-button 
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              @click="closeSidebar"
              block
            >
              Fechar
            </b-button>
          </b-col>
        </b-row>
        </b-overlay>
        </validation-observer>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BSidebar,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
   props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      dataSegments: {
        id: null,
        name: null,
      },
      icon: undefined,

      isLoadingStore: false,
      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    ...mapState("segments", ["segments"]),
  },
  methods: {
    ...mapActions("segments", ["store", "update"]),
    storeSegments(value) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          if (this.dataProp.id == null) { 
            this.store({
              ...this.dataSegments,
              icon: this.icon,
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })
              this.initValues();
              this.closeSidebar();
              this.$emit("fetch-details", 
              {
                active: true,
              });
            })
            .finally(() => {
              this.isLoadingStore = false
            })
          }else{
            this.update({
              ...this.dataSegments,
              icon: this.icon,
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })
              this.initValues();
              this.closeSidebar();
              this.$emit("fetch-details", 
              {
                active: true,
              });
            })
            .finally(() => {
              this.isLoadingStore = false
            })
          }
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },
    initValues(){
      this.dataSegments.name = null,
      this.icon = null,

      this.$nextTick(() => this.$refs.simpleRules.reset());
    },
    closeSidebar(){
      this.$refs['sidebar-create'].hide()
    },
  },
  created() {
  },
  watch: {
    dataProp: {
      immediate: true, 
      handler (val, oldVal) {
        if (!val) return; 
        if (this.dataProp.id == null)  { 
          this.dataSegments.id = null,
          this.initValues(); 
          
        } else { 

          let dataModel = this.$store.getters['segments/getSegmentsById'](this.dataProp.id)

          this.dataSegments.id = null,
          this.dataSegments.id = dataModel.id,
          this.dataSegments.name = dataModel.name
        } 
      }
    }
  }
}
</script>